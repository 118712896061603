// import React from "react";
// import { FooterData1 } from "../../DataForPage/dummyData";
// import { FooterData2 } from "../../DataForPage/dummyData";
// import { FooterData3 } from "../../DataForPage/dummyData";
// import logo from "../../assets/images/shadibazardark.png";
// import googlePlay from "../../assets/images/google-play.svg";
// import appleStore from "../../assets/images/apple-store.svg";

// const Footer = () => {
//   const year = new Date().getFullYear();
//   return (
//     <div>
//       <div className="sm:grid-cols-2 grid grid-cols-4 gap-0 items-center justify-items-center">
//         <div className="sm:w-full sm:pl-8 h-64 mx-auto pl-24">
//           <div>
//             <img className="w-22 cursor-pointer" src={logo} alt="logo"></img>
//           </div>
//           {/* <div className="mt-5">
//             <img
//               className="h-8 cursor-pointer"
//               src={appleStore}
//               alt="apple-store"
//             ></img>
//           </div>
//           <div className="mt-5">
//             <img
//               className="h-8 cursor-pointer"
//               src={googlePlay}
//               alt="apple-store"
//             ></img>
//           </div> */}
//         </div>
//         <div className="sm:pr-4 h-64">
//           <h3 className="text-dark font-inter font-medium text-lg no-underline align-middle tracking-wide normal-case">
//             Features
//           </h3>
//           {FooterData1.map((item) => {
//             return (
//               <div key={item.id}>
//                 <p className="text-dark font-inter text-md font-normal cursor-pointer no-underline align-middle tracking-wide normal-case">
//                   {item.text}
//                 </p>
//               </div>
//             );
//           })}
//         </div>
//         <div className="sm:pr-8 h-64">
//           <h3 className="text-dark font-inter font-medium text-lg no-underline align-middle tracking-wide normal-case">
//             Features
//           </h3>
//           {FooterData2.map((item) => {
//             return (
//               <div key={item.id}>
//                 <p className="text-dark font-inter text-md font-normal cursor-pointer no-underline align-middle tracking-wide normal-case">
//                   {item.text}
//                 </p>
//               </div>
//             );
//           })}
//         </div>
//         <div className="sm:pl-8 h-64">
//           <h3 className="text-dark font-inter font-medium text-lg no-underline align-middle tracking-wide normal-case">
//             Features
//           </h3>
//           {FooterData3.map((item) => {
//             return (
//               <div key={item.id}>
//                 <p className="text-dark font-inter text-md font-normal cursor-pointer no-underline align-middle tracking-wide normal-case">
//                   {item.text}
//                 </p>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//       <div className="flex items-center justify-center">
//         <hr className="sm:w-full h-px w-4/5 bg-gray-400 opacity-50 outline-none border-none" />
//       </div>
//       <div className="flex justify-around items-center py-6">
//         <div>
//           <p className="sm:text-sm text-dark pb-2 font-inter font-medium cursor-pointer no-underline align-middle tracking-wide normal-case">
//             Shadibazar
//           </p>
//         </div>
//         <div>
//           <p className="sm:text-xs text-dark pb-2 font-inter font-light cursor-pointer no-underline align-middle tracking-wide normal-case">
//             Copyright {year} @Alkaaman Event Management Private Limited
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Footer;

import React from "react";
import { FooterData1, FooterData2, FooterData3 } from "../../DataForPage/dummyData";
import logo from "../../assets/images/shadibazardark.png";
import googlePlay from "../../assets/images/google-play.svg";
import appleStore from "../../assets/images/apple-store.svg";
// import facebookIcon from "../../assets/images/facebook.svg"; // Replace with your Facebook icon
import youtube from "../../assets/images/yt.svg";   // Replace with your Twitter icon
import instagramIcon from "../../assets/images/insta.svg"; // Replace with your Instagram icon
import linkedinIcon from "../../assets/images/LINK.svg"; // Replace with your LinkedIn icon
import Facebook from "../../assets/images/fb.svg"; // Replace with your LinkedIn icon

const Footer = () => {

  const year = new Date().getFullYear();
  const handleClick = (link) => {
    if (link) {
      window.open(link, '_blank', 'noopener,noreferrer');
    }
  };
  return (
    <div>
      <div className="sm:grid-cols-2 grid grid-cols-4 gap-0 items-center justify-items-center">
        <div className="sm:w-full sm:pl-8 h-64 mx-auto pl-24">
          <div>
            <img className="w-22 cursor-pointer" src={logo} alt="logo" />
          </div>
          {/* Uncomment if you want to display app store links */}
          {/* <div className="mt-5">
            <img className="h-8 cursor-pointer" src={appleStore} alt="apple-store" />
          </div>
          <div className="mt-5">
            <img className="h-8 cursor-pointer" src={googlePlay} alt="google-play" />
          </div> */}
        </div>
        <div className="sm:pr-8 h-64">
          <h3 className="text-green-500 font-inter font-bold text-lg no-underline align-middle tracking-wide normal-case">
            Policy
          </h3>
          {FooterData2.map((item) => (
            <div
            onClick={()=>handleClick(item?.link)}
            key={item.id}>
              <p className="text-dark font-inter text-md font-normal cursor-pointer no-underline align-middle tracking-wide normal-case">
                {item.text}
              </p>
            </div>
          ))}
        </div>
        <div className="sm:pl-8 h-64">
          <h3 className="text-green-500 font-inter font-bold text-lg no-underline align-middle tracking-wide normal-case">
            Contact us
          </h3>
          {FooterData3.map((item) => (
            <div key={item.id}>
              <p className="text-dark font-inter text-md font-bold cursor-pointer no-underline align-middle tracking-wide normal-case">
                {item.text}
              </p>
              <p className="text-dark font-inter text-md font-normal cursor-pointer no-underline align-middle tracking-wide normal-case">
                {item.text2}
              </p>
            </div>
          ))}
        </div>
        <div className="sm:pr-4 h-64">
          <h3 className="text-green-500 font-inter font-bold text-lg no-underline align-middle tracking-wide normal-case">
            Follow Us On
          </h3>
          {/* {FooterData1.map((item) => (
            <div key={item.id}>
              <p className="text-dark font-inter text-md font-normal cursor-pointer no-underline align-middle tracking-wide normal-case">
                {item.text}
              </p>
            </div>
          ))} */}
          <div className="flex space-x-4 mb-4">
          <a href="https://www.facebook.com/OfficialShadibazar" target="_blank" rel="noopener noreferrer">
            <img className="h-6 cursor-pointer" src={Facebook} alt="Facebook" />
          </a>
          <a href="https://www.youtube.com/@shadibazar" target="_blank" rel="noopener noreferrer">
            <img className="h-6 cursor-pointer" src={youtube} alt="youtube" />
          </a>
          <a href="https://www.instagram.com/officialshadibazar" target="_blank" rel="noopener noreferrer">
            <img className="h-6 cursor-pointer" src={instagramIcon} alt="Instagram" />
          </a>
          <a href="https://www.linkedin.com/company/officialsadibazar/" target="_blank" rel="noopener noreferrer">
            <img className="h-6 cursor-pointer" src={linkedinIcon} alt="LinkedIn" />
          </a>
        </div>
        </div>
       
      </div>
      <div className="flex items-center justify-center">
        <hr className="sm:w-full h-px w-4/5 bg-gray-400 opacity-50 outline-none border-none" />
      </div>
      <div className="flex flex-col items-center py-6">
        
        <div>
          <p className="sm:text-sm text-green-700 pb-2 font-inter font-bold cursor-pointer no-underline align-middle tracking-wide normal-case">
            Shadibazar
          </p>
        </div>
        <div>
          <p className="sm:text-xs text-dark pb-2 font-inter font-light cursor-pointer no-underline align-middle tracking-wide normal-case">
            Copyright {year} @Alkaaman Event Management Private Limited
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
