import React from "react";
import "./App.css";
import Home from "./Components/AllComponents/Home";
import ThemeContext from "./Components/ContextWrapper/ThemeContext";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div>
    <Helmet>
      <title>ShadiBazar - Best Event Planning Services</title>
      <meta name="description" content="ShadiBazar offers the best event planning services. Book wedding furniture, dresses Like Lehenga & Sherwani On Rent,Invitation Card,Catering, Sound System, jewelry, and more." />
      <meta name="keywords" content="shadibazar, event planning, wedding services, furniture rental,Lehenga & Sherwani On rent Birthday Party booking" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.shadibazar.com/" />
    </Helmet>
    <ThemeContext>
      <Home />
    </ThemeContext>
  </div>

  );
}

export default App;
