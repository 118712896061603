import lehenga from "../assets/images/dressn.png";
import sofa2 from "../assets/images/roseChair.png";
import jewellery from "../assets/images/jewellery.png";
import invitation from "../assets/images/4.png";
import phone1 from "../assets/images/bedRoom.png";
import blueSofa from "../assets/images/blueSofa.png";

export const navbarData = [
  {
    id: "1",
    name: "Home",
    link: "news",
  },
  {
    id: "2",
    name: "About Us",
    link: "company",
  },
  {
    id: "3",
    name: "Wedding Services",
    link: "features",
  },
  // {
  //   id: "4",
  //   name: "Career",
  //   link: "room",
  // },
  // {
  //   id: "5",
  //   name: "Contact US",
  //   link: "demo",
  // },
  // {
  //   id: "6",
  //   name: "Login",
  //   link: "/",
  // },
];
export const FooterData1 = [
  {
    id: "1",
    text: "Modern",
  },
  {
    id: "2",
    text: "Decorations",
  },
  {
    id: "3",
    text: "Events",
  },
  {
    id: "4",
    text: "Career",
  },
  {
    id: "5",
    text: "Forum",
  },
  {
    id: "6",
    text: "Contacts",
  },
];
export const FooterData2 = [
  {
    id: "1",
    text: "Privacy policy",
    path: "Resources",
    link:"https://booking.shadibazarevents.com/page/privacy-policy"
  },
  {
    id: "2",
    text: "Terms of use",
    link:"https://merchant.razorpay.com/policy/MII2gjby3ARFRX/terms"
  },
  {
    id: "3",
    text: "Become A seller",
    link:"https://booking.shadibazarevents.com/seller/sign-up"
  },
];
export const FooterData3 = [
  {
    id: "1",
    text: "For Business",
    text2:"business@shadibazarevents.com"
  },
  {
    id: "2",
    text: "Customer support",
    text2:"1800-203-1228,8360105386"
  },
  {
    id: "3",
    text: "Media",
  },
  {
    id: "4",
    text: "Marketing",
  },
];

export const sliderData = [
  {
    id: "1",
    title: "Furniture",
    text: "With Shadibazar, you Can Book Furniture At No cost EMI. Let furniture find there place in new beautifule warm room.",
    type: "Room1",
    img: sofa2,
  },
  {
    id: "2",
    title: "Dress",
    text: "With Shadibazar, you Can Book Sherwani & Lehenga On Rent also, Visit Our Booking Website To know More to explore beautifule modern and premium designs.",
    type: "Room2",
    img: lehenga,
  },
  {
    id: "3",
    title: "Jewellery",
    text: "Shadibazar is your best Platform for Artificial Jewellery, Visit Our Booking Website To know More.",
    type: "Room3",
    img: jewellery,
  },
  {
    id: "4",
    title: "Invitation Card",
    text: "Shadibazar is your best Platform for Invitation cards, Visit Our Booking Website To know More.",
    type: "Room3",
    img: invitation,
  },
  {
    id: "5",
    title: "Make Up",
    text: "Shadibazar is your best Platform for Catering Facilities, Visit Our Booking Website To know More.",
    type: "Room3",
    img: jewellery,
  },
  {
    id: "6",
    title: "Make Up",
    text: "Shadibazar is your best Platform for Catering Facilities, Visit Our Booking Website To know More.",
    type: "Room3",
    img: jewellery,
  },
];
export const tabsData = [
  {
    id: "0",
    label: "Dress",
    value: "bridal",
    desc: `👰 Discover a curated collection of exquisite bridal wear and groom's attire. Whether you wish to purchase or rent, our selection offers something for everyone.`,
    img: phone1, // Replace with an image related to bridal wear if available
    linkText: "See more",
  },
  {
    id: "1",
    label: "Lighting",
    value: "lighting",
    desc: `💡 Create a captivating atmosphere with our advanced lighting solutions, including LED walls, sharpy lights, and par lights.`,
    img: phone1, // Replace with an image related to event lighting if available
    linkText: "See more",
  },
  {
    id: "2",
    label: "Sound",
    value: "entertainment",
    desc: `🎶 Bring your event to life with professional musicians, entertainers, and DJs who set the perfect tone for your celebration.`,
    img: phone1, // Replace with an image related to entertainment if available
    linkText: "See more",
  },
  {
    id: "3",
    label: "Camera",
    value: "photography",
    desc: `📸 Capture every precious moment with our expert photography and videography services. We ensure that your memories are preserved beautifully.`,
    img: phone1, // Replace with an image related to photography and videography if available
    linkText: "See more",
  },
  {
    id: "4",
    label: "Decor",
    value: "decor",
    desc: `🎨 Transform your venue with our stunning decor options that reflect your unique style.`,
    img: phone1, // Replace with an image related to decor and venue styling if available
    linkText: "See more",
  },
];
export const cardData = [
  {
    id: "0",
    title: "Explore Our Services",
    text: "Discover our wide range of services, from exquisite bridal wear and modern event lighting to professional entertainment and decor options. Choose what suits your needs and contact us for more information or a demo.",
  },
  {
    id: "1",
    title: "Bridal Lehengas & Sherwanis",
    text: "Explore our curated collection of bridal lehengas and sherwanis. Whether you want to rent or purchase, find the perfect attire for your special day.",
  },
  {
    id: "2",
    title: "Event Lighting & Decor",
    text: "Transform your event with our advanced lighting solutions and stunning decor options. Create the perfect atmosphere for your celebration with our expert services.",
  },
  {
    id: "3",
    title: "Entertainment & Photography",
    text: "Bring your event to life with our musical groups, DJs, and expert photography and videography services. Capture every moment and enjoy professional entertainment.",
  },
  {
    id: "4",
    title: "Connect with Us",
    text: "Setup your event with AxeL effortlessly. Contact us to explore our services, request a demo, or get a custom quote tailored to your needs.",
  },
];


export const room = [
  {
    id: "1",
    name: "Book Dress for Bride & Groom. ",
    img: blueSofa,
  },
  {
    id: "2",
    name: "Book Invitation Cards.",
    img: blueSofa,
  },
  {
    id: "3",
    name: "Book Catering Facilities.",
    img: blueSofa,
  },
  {
    id: "3",
    name: "Book Sound Systems.",
    img: blueSofa,
  },
  {
    id: "3",
    name: "Book Decoration Services.",
    img: blueSofa,
  },
  {
    id: "3",
    name: "And Many More .....",
    img: blueSofa,
  },
];
