// import React, { useContext, useRef } from "react";
// import Header from "../Header/Header";
// import Navbar from "../Navbar/Navbar";
// import WrapperContainer from "../WrapperContainer/WrapperContainer";
// import MidFilterSection from "../MidSection/MidFilterSection";
// import MidComponentParent from "../MidSection/MidComponentParent";
// import TabsComponent from "../MidSection/TabsComponent";
// import FurnitureFlow from "../MidSection/FurnitureFlow";
// import NewsLetter from "../MidSection/NewsLetter";
// import Cards from "../MidSection/Cards";
// import Footer from "../Footer/Footer";
// import PopUpButton from "../PopUpButton/PopUpButton";
// import { ThemeBgContext } from "../ContextWrapper/ThemeContext";
// import MobileNav from "../Navbar/MobileNav";

// const Home = () => {
//   const sectionTop = useRef();
//   const { theme } = useContext(ThemeBgContext);
//   const handleScroll = () => {
//     sectionTop.current?.scrollIntoView({ behavior: "smooth" });
//   };
//   return (
//     <div className="relative">
//       <MobileNav></MobileNav>
//       <div>
//         <PopUpButton handleScroll={handleScroll}></PopUpButton>
//       </div>
//       <div className={theme === "light" ? "bg-white" : "bg-dark"}>
//         <WrapperContainer navHead>
//           <Navbar></Navbar>
//           <Header topRef={sectionTop}></Header>
//         </WrapperContainer>
//       </div>
//       <div className="bg-gray-100 w-full">
//         <WrapperContainer>
//           <MidFilterSection></MidFilterSection>
//           <MidComponentParent></MidComponentParent>
//           <TabsComponent></TabsComponent>
//           {/* <FurnitureFlow></FurnitureFlow> */}
//           <Cards></Cards>
//           <NewsLetter></NewsLetter>
//         </WrapperContainer>
//         <div>
//           <Footer></Footer>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Home;


import React, { useContext, useRef } from "react";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import WrapperContainer from "../WrapperContainer/WrapperContainer";
import MidFilterSection from "../MidSection/MidFilterSection";
import MidComponentParent from "../MidSection/MidComponentParent";
import TabsComponent from "../MidSection/TabsComponent";
import Footer from "../Footer/Footer";
import PopUpButton from "../PopUpButton/PopUpButton";
import { ThemeBgContext } from "../ContextWrapper/ThemeContext";
import MobileNav from "../Navbar/MobileNav";
import { Helmet } from "react-helmet";

const Home = () => {
  const sectionTop = useRef();
  const { theme } = useContext(ThemeBgContext);
  const handleScroll = () => {
    sectionTop.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Structured Data for Organization
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ShadiBazar",
    "url": "https://www.shadibazar.com",
    "logo": "https://shadibazarevents.com/static/media/shadibazardark.e421d3ccd0c7efb59066.png",  // Replace with your logo URL
    "sameAs": [
      "https://www.youtube.com/@shadibaza",
      "https://www.facebook.com/OfficialShadibazar"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "1800-203-1228, 836 010 5386",
      "contactType": "Customer Service"
    }
  };

  return (
    <div className="relative">
      <Helmet>
        <title>ShadiBazar - Home</title>
        <meta name="description" content="Explore ShadiBazar's comprehensive event planning services, from furniture rental to bridal dresses." />
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
      <MobileNav />
      <div>
        <PopUpButton handleScroll={handleScroll} />
      </div>
      <div className={theme === "light" ? "bg-white" : "bg-dark"}>
        <WrapperContainer navHead>
          <Navbar />
          <Header topRef={sectionTop} />
        </WrapperContainer>
      </div>
      <div className="bg-gray-100 w-full">
        <WrapperContainer>
          <MidFilterSection />
          <MidComponentParent />
          <TabsComponent />
          {/* <Cards />
          <NewsLetter /> */}
        </WrapperContainer>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
